import React from 'react'
import MetaData from '../components/MetaData'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import { Section, Title, Text } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {Link} from 'gatsby'
import setLanguage from '../helpers/languageConfig'


const TermsConditions = () => {
  const { t, ready } = useTranslation(['terms'], { useSuspense: false })
  const lang = setLanguage();
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
    
  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        <Section bg={userDarkMode ? 'black' : 'light'}>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('updated')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  US_ACTIVE\119969781\V-28
                </Text>
                <Title className="mt-5" variant="hero" color={userDarkMode ? 'light' : 'dark'}>
                  {t('heroTitle')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('thisLSAgreement')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('pleaseRead')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('youRepresent')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('inAddition')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('yourUse')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Link to={`/${lang}/supporting-documents`}>
                      <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                        {t('cryptoTrading')}
                      </Text>
                    </Link>
                  </li>
                  <li type="circle">
                    <Link to={`/${lang}/supporting-documents`}>
                      <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('accountOpening')}
                      </Text>
                    </Link>
                  </li>
                  <li type="circle">
                    <Link to={`/${lang}/supporting-documents`}>
                      <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                        {t('fees')}
                      </Text>
                    </Link>
                  </li>
                  <li type="circle">
                    <Link to={`/${lang}/supporting-documents`}>
                      <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('tradingRules')}
                      </Text>
                    </Link>
                  </li>
                  <li type="circle">
                    <Link to={`/${lang}/supporting-documents`}>
                      <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                        {t('privacy')}
                      </Text>
                    </Link>
                  </li>
                  <li type="circle">
                    <Link to={`/${lang}/supporting-documents`}>
                      <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('electronic')}
                      </Text>
                    </Link>
                  </li>
                </ul>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('keyTerms')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('theFollowing')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('marketMakers')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('crypto')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('custodian')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('custodialAccount')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('externalExch')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('extExchAcc')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('extExchTx')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('LSAccount')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('LStx')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('LStech')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('thirdParty')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('txs')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('access')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('forTheExclusive')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('youMay')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('LSregistration')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('useOfThe')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('externalExchLinking')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('youMayLink')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('custodialAccCreation')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('openingCustodial')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('youAppoint')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('transferFunds')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('AllOrders')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('LSforwards')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ordersForTxs')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('MarketOrders')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('LimitOrders')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('StopLimit')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('TheReference')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('obtainingAccess')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('TxsAreSubject')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('LSisNonCustodial')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('AccClosure')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('YouMayClose')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('AllReqs')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('NoRelationship')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('YourLsAcc')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('CurrsShown')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('SelfDirected')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('LSisSelf')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('UnderNo')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ToTheExtent')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('TheValue')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('LSdoesNot')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('fees')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('AllFees')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Tax')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Download')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('AnyLegal')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('TradingRisks')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('NotResponsible')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('WithoutLimiting')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('DoesNotHave')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('OtherOrders')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('ThirdParty')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('ReRoute')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('SystemDelays')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('MayNot')}
                    </Text>
                  </li>
                </ul>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">{
                  t('Downtime')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('reasonable')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('PartOf')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('24/7')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('AccHolder')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('AuthToShare')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('YouHereby')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('PleaseRefer')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('AuthToSend')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('TransferReqs')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('NoFDIC')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('HighRisk')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Neither')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Broker')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('TheTxs')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Supported')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('MayDiscontinue')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Underlying')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Aknowledge')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Intelectual')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Protected')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Disclaimer')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('AsIs')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Role')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ShallHave')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Limitation')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('NoImplied')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('UnderNoCirc')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Indemnification')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Indemnify')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('RestrictedPersons')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Warrant')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('NotAuth')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('Citizen')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('Corporate')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('Included')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('Otherwise')}
                    </Text>
                  </li>
                </ul>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Compliance')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('YourAccess')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('ElectCom')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('AllElectronic')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('WeMayAmend')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Unless')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Severability')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('IfAny')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Assignment')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ShallNot')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Binding')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ThisAgreement')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Waiver')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('WeAndYou')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('ClassAction')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ThereWill')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Force')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('NoParty')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Captions')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('TheSection')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Entire')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('AllPolicies')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('Modifications')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('Reserves')}
                </Text>
                
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default TermsConditions
